import './listMemberInWorkspace.scss';
import { useState, useEffect, useContext } from 'react';
import { getListMemberInWorkspace, deleteMemberInWorkspace, updateRoleMemberInWorkspace } from '../../store/index.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { TextField, Autocomplete, createFilterOptions } from "@mui/material";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Popup } from '../UI/Popup/Popup';
import { ConfirmDelete } from '../UI/Popup/ConfirmDelete';
import { UpgradePlan } from "../UI/Popup/UpgradePlan";
import { InvitMemberInWorkspace } from './InvitMemberInWorkspace';
import { StoreContext } from '../../store/store';

export const ListMemberInWorkspace = () => {
    const { workspace, subscription } = useContext(StoreContext);
    const [listMember, setListMember] = useState({loading: true, data: []});
    const [showPopupConfirmDelete, setShowPopupConfirmDelete] = useState({show: false});
    const [showPopupInvitMember, setShowPopupInvitMember] = useState({show: false});
    const [showPopupUpgradePlan, setShowPopupUpgradePlan] = useState({show: false});
    const { t } = useTranslation();
    const { control } = useForm();

    const listRole = [
        {
            value: 'admin',
            label: 'Administrateur'
        },
        {
            value: 'support',
            label: 'Support'
        },
        {
            value: 'comptable',
            label: 'Comptable'
        },
        {
            value: 'editeur',
            label: 'Editeur'
        }
    ]; 

    useEffect(() => {
        handleGetData();
    }, []);

    const handleGetData = () => {
        getListMemberInWorkspace().then(res => {
            setListMember({loading: false, data: res ?? []});
        });
    }

    const handleDelete = () => {
        deleteMemberInWorkspace(showPopupConfirmDelete.id).then(() => {
            setShowPopupConfirmDelete({show: false});
            handleGetData();
        }).catch(() => {
            setShowPopupConfirmDelete({show: false});
        });
    }

    const handleChangeRole = (event, newValue, idUser) => {
        let data = { role: newValue.value }
        updateRoleMemberInWorkspace(data, idUser).then(() => {
            handleGetData();
        });
    }

    return (
        <div className="list-member">
            <div className="member-header">
                <h3>Membres - {listMember.data.length}/{subscription?.nbMaxUserInWorkspace}</h3>

                {subscription === null ? (
                    <button className="btn-submit" onClick={() => setShowPopupUpgradePlan({ show: true })}>
                        Inviter un membre
                    </button>
                ) : listMember.data.length < subscription?.nbMaxUserInWorkspace ? (
                    <button className="btn-submit" onClick={() => setShowPopupInvitMember({ show: true })}>
                        Inviter un membre
                    </button>
                ) : (
                    <button className="btn-submit" onClick={() => setShowPopupUpgradePlan({ show: true })}>
                        Inviter un membre
                    </button>
                )}

            </div>
            <table>
                <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Email</th>
                        <th>Roles</th>
                        <th>Depuis le</th>
                        <th>Date d'invitation</th>
                        <th>Actif</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {listMember.data.map((item, index) => (
                        <tr key={index}>
                            <td>{item.userFirstName} {item.userLastName}</td>
                            <td>
                                {item.userId === null && <FontAwesomeIcon icon={faEnvelope} />}
                                {item.userEmail}    
                            </td>
                            <td>
                                <Autocomplete
                                    options={listRole}
                                    disableClearable={true}
                                    fullWidth
                                    openOnFocus
                                    selectOnFocus
                                    defaultValue={listRole.find(el => el.value === item.role) ?? null}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label="Rôle"
                                        />
                                    }
                                    onChange={(event, newValue) => {
                                        handleChangeRole(event, newValue, item.id);
                                    }}
                                    size="small"
                                    disablePortal
                                    ListboxProps={{ style: { maxHeight: "175px" } }}
                                />
                            </td>
                            <td>{item.acceptedAt && new Date(item.acceptedAt.date).toLocaleDateString()}</td>
                            <td>{item.invitedAt && new Date(item.invitedAt.date).toLocaleDateString()}</td>
                            <td>{item.status}</td>
                            <td>
                                {item.role !== 'admin' && <FontAwesomeIcon icon={faTrash} onClick={() => setShowPopupConfirmDelete({show: true, id: item.id})} />}
                                {item.userId === null && <FontAwesomeIcon icon={faEnvelope} />}
                            </td>
                        </tr> 
                    ))}
                </tbody>
            </table>
            { showPopupConfirmDelete.show &&
                <Popup closePopup={() => setShowPopupConfirmDelete({ show: false })} customContent={true}>
                    <ConfirmDelete msg={t('template_popup_delete')} handleDelete={handleDelete} />
                </Popup>
            }

            { showPopupInvitMember.show &&
                <Popup closePopup={() => setShowPopupInvitMember({ show: false })} customContent={true}>
                    <InvitMemberInWorkspace />
                </Popup>
            }

            { showPopupUpgradePlan.show &&
                <Popup closePopup={() => setShowPopupUpgradePlan({ show: false })} customContent={true}>
                    <UpgradePlan />
                </Popup>
            }
        </div>
  	);
} 