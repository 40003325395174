
import React, { useContext } from 'react';
import { FormInputText } from "../../../components/UI/Form/FormInputText";
import { FormAutocomplete } from "../../../components/UI/Form/FormAutocomplete";
import { StoreContext } from "../../../store/store";
import iconPremium from "../../../assets/images/icons/icon-premium.svg";

export const Base  = (props) => {
    const { control } = props;
    const { subscription } = useContext(StoreContext);

    const typeTemplate = [
        { id: 1, name: "Standard" },
        { id: 2, name: "Carrousel" }
    ];

	return (
        <div className="base">
            <div className="form-group">
                <FormInputText name="name" control={control} rules={{ required: true }} label="Nom du template ( meta ) *" />
                
                <FormAutocomplete
                    name="typeTemplate"
                    control={control}
                    label="Type de template *"
                    options={typeTemplate}
                    rules={{ required: true }}
                    getOptionLabel={option => option.name}
                    renderOption={(props, option) => {
                        let isDisabled = false;
                    
                        if (subscription?.planification === false) {
                            isDisabled = option.id === 2;
                        }
                    
                        return (
                            <li {...props} key={option.id}>
                                {subscription?.planification === false && isDisabled && <img className="icon-premium" src={iconPremium} alt="lock" />}
                                {option.name}
                            </li>
                        );
                    }}
                    clearable={true}
                /> 
                
            </div>
        </div>
	);
};
