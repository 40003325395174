import React from 'react'; // Assurez-vous d'importer React
import { toast, Bounce } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const CustomToast = ({ name, newData }) => {
	const { t } = useTranslation();

    return (
        <div>
            {(() => {
                switch (name) {
                    case 'metaConnected':
                        return (
                            <div>
                                <p>Meta connecté</p>
                            </div>
                        );
                    case 'createTemplate':
                        return (
                            <div>
                                <p>Template "{newData?.name}" créé</p>
                                <p>Il faut maintenant attendre son approbation par Meta (quelques heures)</p>
                            </div>
                        );
                    case 'draftTemplate':
                        return (
                            <div>
                                <p>Template "{newData?.name}" enregistré comme brouillon</p>
                            </div>
                        );
                    case 'updateTemplate':
                        return (
                            <div>
                                <p>Template "{newData?.name}" modifié</p>
                            </div>
                        );
                    case 'deleteTemplate':
                        return (
                            <div>
                                <p>Template supprimé</p>
                            </div>
                        );
                    case 'duplicateTemplate':
                        return (
                            <div>
                                <p>Template dupliqué</p>
                            </div>
                        );
                    case 'createCampaign':
                        return (
                            <div>
                                <p>Campagne créée</p>
                                <p>Nom de la campagne : {newData?.name}</p>
                            </div>
                        );
                    case 'updateTemplate':
                        return (
                            <div>
                                <p>Campagne mis à jour</p>
                                <p>Nom de la campagne : {newData?.name}</p>
                            </div>
                        );
                    case 'createContact':
                        return (
                            <div>
                                <p>Contact créé</p>
                            </div>
                        );
                    case 'updateContact':
                        return (
                            <div>
                                <p>Contact modifié</p>
                            </div>
                        );
                    case 'deleteContact':
                        return (
                            <div>
                                <p>Contact retiré du groupe</p>
                            </div>
                        );
                    case 'createGroups':
                        return (
                            <div>
                                <p>Groupe créé</p>
                            </div>
                        );
                    case 'updateGroups':
                        return (
                            <div>
                                <p>Groupe modifié</p>
                            </div>
                        );
                    case 'deleteGroups':
                        return (
                            <div>
                                <p>Groupe supprimé</p>
                            </div>
                        );
                    case 'addContactToGroup':
                        return (
                            <div>
                                <p>
                                    {t(`contact_added_to_${newData?.groupsCount > 1 ? 'many_groups' : 'group'}`, {
                                        count: newData?.contactsCount,
                                        name: newData?.name || 'Groupe sans nom',
                                    })}
                                </p>
                            </div>
                        );
                    case 'toMuchText':
                        return (
                            <div>
                                <p>Vous avez trop de texte</p>
                            </div>
                        );
                    case 'emptyText':
                        return (
                            <div>
                                <p>Vous n'avez pas mis de texte dans votre message.</p>
                            </div>
                        );
                    case 'errorTemplateName':
                        return (
                            <div>
                                <p>Le nom du template existe déjà, veuillez en choisir un autre.</p>
                            </div>
                        );
                    case 'memberChangeRole':
                        return (
                            <div>
                                <p>Role modifié</p>
                            </div>
                        );
                    case 'removeMemberInWorkspace':
                        return (
                            <div>
                                <p>Membre supprimé</p>
                            </div>
                        );
                    case 'updateWorkspace':
                        return (
                            <div>
                                <p>Espace de travail modifié</p>
                            </div>
                        );
                    case 'updateProfil':
                        return (
                            <div>
                                <p>Profile modifié</p>
                            </div>
                        );
                    case 'createField':
                        return (
                            <div>
                                <p>Colonne créée</p>
                            </div>
                        );
                    case 'imgSizeLimit':
                        return (
                            <div>
                                <p>L'image ne doit pas dépasser 5 Mo</p>
                            </div>
                        );
                    case 'videoSizeLimit':
                        return (
                            <div>
                                <p>La vidéo ne doit pas dépasser 12 Mo</p>
                            </div>
                        );
                    case 'videoFormatNotMOV':
                        return (
                            <div>
                                <p>Le format .mov n'est pas accepté</p>
                            </div>
                        );
                    case 'subscriptionCanceled':
                        return (
                            <div>
                                <p>Abonnement arrêté avec succès</p>
                            </div>
                        );
                    default:
                        return (
                            <div>
                                <h3>Une erreur est survenue</h3>
                            </div>
                        );
                }
            })()}
        </div>
    );
};

export const Toastify = (name, type = "success", data) => {
    toast[type](<CustomToast name={name} newData={data} />, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
    });
};
