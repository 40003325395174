import './subscription.scss';
import { NavigationAccount } from "../../../components/NavigationAccount/NavigationAccount";
import { PricingSubscription } from "../../../components/PricingSubscription/PricingSubscription";
import { useContext } from 'react';
import { StoreContext } from "../../../store/store";
import { removeSubscription } from '../../../store/index.service';
import { Toastify } from "../../../components/UI/Toastify/Toastify";

export const Subscription = () => {
    const { workspace } = useContext(StoreContext);
    const plan = workspace?.subscription?.plan ?? "Plan gratuit";
    const nextPaymentDate = workspace?.subscription?.endDate.date ?? "Aucun";
    const amount = workspace?.subscription?.planAmount ?? "0";

    const handleStopSubscription = () => {
        let data = {
            idStripeSubscription: workspace?.subscription?.idStripeSubscription
        }

        removeSubscription(data)
            .then(() => {
                Toastify('subscriptionCanceled', 'success');
            })

    }

    return (
        <div className="subscription">
            <NavigationAccount active="subscription" />

            <div className="subscription-content">
                <div className="myPlan backgroundWhite">
                    <div className="myPlanHeader">
                        <h2>Votre abonnement : </h2>
                        <span className={(plan !== "free" && plan !== "beta" && plan !== "Plan gratuit") ? 'paid' : 'free'}>
                            {(plan !== "free" && plan !== "beta" && plan !== "Plan gratuit") ? 'Abonnement actif' : 'Abonnement inactif'}
                        </span>
                        {(plan !== "free" && plan !== "beta" && plan !== "Plan gratuit") && workspace?.workspaceRole === 'admin' && (
                            <button className="btn" onClick={handleStopSubscription}>Stop abonnement</button>
                        )}
                    </div>
                    
                    <div className="myPlanContent">
                        <div className="myPlanContentItem">
                            <p>Plan : {plan}</p>
                        </div>
                        <div className="myPlanContentItem">
                            {plan !== "free" && plan !== "beta" && plan !== "Plan gratuit" && (
                                <p>Prochain paiement le {new Date(nextPaymentDate).toLocaleDateString()}</p>
                            )}
                        </div>
                        <div className="myPlanContentItem">
                            <p>Montant : {(amount / 100)}€</p>
                        </div>
                    </div>
                    
                </div>
            </div>

            <PricingSubscription />

        </div>
    )
}